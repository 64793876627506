import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validator,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

import { NgRecaptcha3Service } from 'ng-recaptcha3';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(
    private formbuilder: FormBuilder,
    private toastrService: ToastrService,
    private authservice: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private recaptcha3: NgRecaptcha3Service
  ) {}
  loading = false;
  submitted = false;
  loginForm: FormGroup;
  recaptcha3_pagekey: string = '6Lf3ZhYbAAAAALtVXJKIqcKGhQW68IWJlwu_x_1x';

  ngOnInit(): void {
    this.authservice.logout();
    this.loginForm = this.formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required],
    });
    this.recaptcha3.init(this.recaptcha3_pagekey);
  }
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.recaptcha3.getToken().then(
      (token) => {
        const formData = this.loginForm.value;
        formData.recaptchaToken = token;
        let loginmodel = Object.assign({}, this.loginForm.value);

        this.authservice.login(loginmodel).subscribe(
          (res) => {
            if (res.BasariliMi) {
              this.toastrService.info('Giriş Başarılı', 'Başarılı!');

              const returnUrl =
                this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
              this.router.navigateByUrl(returnUrl);
            } else {
              this.toastrService.error(res.Mesaj, 'Dikkat!1');
              this.loading = false;
            }
          },
          (err) => {
            this.toastrService.error(err.Mesaj, 'Dikkat!2');
            this.loading = false;
          }
        );
      },
      (error) => {
        this.toastrService.error('recaptcha Doğrulanamadı', 'Dikkat!');
        this.loading = false;
      }
    );
  }
}
